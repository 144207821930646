import { useEffect, useState } from "react";
import { styled } from "styled-components";
import { toast } from "react-hot-toast";
import { useParams, useLocation } from "react-router";
import { useNavigate } from 'react-router-dom';

// components
import PostCard from "./PostCard";
import Message from "./Message";
import DownArrow from "./DownArrow";
import ButtonOutline from "../../ui/ButtonOutline";
import ButtonBlack from "../../ui/ButtonBlack";
import ApprovalCount from "../approval/ApprovalCount";


// other
import api from "../../../services/api/api";

const PostScreen: React.FC = () => {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdatingSkip, setIsUpdatingSkip] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<any>();
  const params = useParams();
  const location = useLocation();
  const fullPath = window.location.origin + location.pathname;
  const navigate = useNavigate();

  const isOrganic = !data?.boost_post?.length;
  const isApproved = !!data?.client_approved;

  const fetchUrl = `/pa/social-post/info/${params.token}`;

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop = window.scrollY;

    if (scrollTop + windowHeight + 50 >= documentHeight) {
      setIsScrolledToBottom(true);
    } else {
      setIsScrolledToBottom(false);
    }
  };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  useEffect(() => {
    let timeout1: NodeJS.Timeout;
    let timeout2: NodeJS.Timeout;

    const onScroll = () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
      timeout1 = setTimeout(handleScroll, 30);
      timeout2 = setTimeout(handleScroll, 150);
    };

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
      clearTimeout(timeout1);
      clearTimeout(timeout2);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let resp;
      try {
        resp = await api.get(fetchUrl + location.search);
        if (!resp.data.hasOwnProperty("id")) {
          throw new Error();
        }
        setData(resp.data);
      } catch (err) {
        setIsError(true);
      }
    };
    fetchData();
  }, [fetchUrl, params.token]);
  
  const skipHandler = async () => {
    setIsUpdatingSkip(true);
    try {

      const method = isApproved ? '/approve' : '/skip';

      await api.get(`/pa/content-approval/`  + data.content_approval.id + method);
        toast.success("Post has been skipped!");
        setIsUpdatingSkip(false);
        setData(null)
        window.scrollTo(0, 0);
        navigate(data.content_approval.next);
    } catch {
      toast.error("Something went wrong. Try again!");
      setIsUpdatingSkip(false);
    }
  };

  const approveHandler = async () => {
    setIsUpdating(true);
    let response;
    try {
      response = await api.get(`/pa/social-post/save/${params.token}`  + location.search);
      if (response.data === "success") {
        toast.success("Item has been approved!");

        if(data.content_approval){
          setData(null);
          navigate(data.content_approval.next);
        } else {
          const resp = await api.get(`/pa/social-post/info/${params.token}`);
          setData(resp.data);
        }
        
        setIsUpdating(false);
      } else {
        throw new Error();
      }
    } catch {
      toast.error("Something went wrong. Try again!");
      setIsUpdating(false);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(fullPath)
      .then(() => {
        toast.success("Copied to the clipboard!");
      })
      .catch((error) => {
        toast.error("Something went wrong... Couldn't perform this operation");
      });
  };

  if (isError) {
    return (
      <Container>
        <LoaderContainer>
          It looks like you have a wrong link...
        </LoaderContainer>
      </Container>
    );
  }

  if (!data) {
    return (
      <Container>
        <LoaderContainer>Loading...</LoaderContainer>
      </Container>
    );
  }

  return (
    <Container>
      {data.content_approval && <ApprovalCount pagination={data.content_approval}></ApprovalCount>}
      <PostCard data={data} />
      <MessageContainer>
        <Message data={data} isOrganic={isOrganic} />
      </MessageContainer>
      <BottomContainer>
        {!isScrolledToBottom && <DownArrow />}
        {isScrolledToBottom && (
          <>
            <ButtonOutline label="Copy link" clickHandler={copyToClipboard} />
            {data.content_approval && <ButtonOutline label="Skip" clickHandler={skipHandler} isLoading={isUpdatingSkip}/>}
            {isApproved ? (
              <ButtonBlack
                label="Approved!"
                bgColor="#9B7CEB"
                onClick={() => {}}
              />
            ) : (
              <ButtonBlack
                label="Approve"
                onClick={approveHandler}
                isLoading={isUpdating}
              />
            )}
          </>
        )}
      </BottomContainer>
    </Container>
  );
};

export default PostScreen;

const LoaderContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding: 1rem 1rem 14rem 1rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MessageContainer = styled.div`
  height: 24.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BottomContainer = styled.div`
  position: fixed;
  bottom: 1.2rem;
  gap: 1rem;
  width: 100%;
  padding: 0 1rem 2rem 1rem;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
