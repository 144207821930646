import { styled } from "styled-components";
import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import ReactPlayer from "react-player";

// components
import ButtonOutline from "../../ui/ButtonOutline";
import Link from "./Link";
import Dots from "./Dots";
import SocialLabel from "./SocialLabel";

function isVideoUrl(url: string): boolean {
  const supportedExtensions = ["mp4", "webm", "ogg", "mov"];
  const extention = url.slice(-3);
  const isVideo = supportedExtensions.some((string) => {
    return string.toLowerCase() === extention.toLowerCase();
  });
  return isVideo;
}

type Props = {
  data: any;
};

const PostCard: React.FC<Props> = ({ data }) => {
  const [currentImg, setCurrentImg] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const socialPlatformsCount = data?.social_networks.length;

  const isVideoPost =
    data.post_type.name === "Video Post" ||
    isVideoUrl(data?.files[0]?.reference);

  console.log(data);

  const convertToParagraphs = (text: string) => {
    return text.split(/\r\n/);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImg((prevImg) => (prevImg + 1) % data.files.length);
    }, 3000);
    intervalRef.current = interval;

    return () => {
      clearInterval(intervalRef.current!);
    };
  }, [data.files]);

  useEffect(() => {
    if (containerRef.current) {
      const width = containerRef.current.clientWidth;
      setContainerWidth(width);
    }
  }, []);

  const handleNextImg = () => {
    setCurrentImg((prevImg) => (prevImg + 1) % data.files.length);
    clearInterval(intervalRef.current!); // Clear the interval when changing the image
    intervalRef.current = setInterval(() => {
      setCurrentImg((prevImg) => (prevImg + 1) % data.files.length);
    }, 3000);
  };

  return (
    <Container ref={containerRef}>
      {isVideoPost && (
        <ReactPlayer
          url={data.files[0].reference} // or any other video source
          width="100%"
          height="100%"
          controls={true}
        />
      )}
      {!isVideoPost && (
        <ImgContainer $height={containerWidth} onClick={handleNextImg}>
          <Img
            key={currentImg}
            src={`${data.files[currentImg].reference}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, ease: "easeOut" }}
          />
        </ImgContainer>
      )}
      {data.files.length > 1 && (
        <DotsContainer>
          <Dots
            currentImg={currentImg}
            totalImgs={data.files.length}
            onClick={handleNextImg}
          />
        </DotsContainer>
      )}
      <ContentContainer>
        {data?.social_networks.map((platform: any, index: number) => (
          <Caption
            $isLast={index === socialPlatformsCount - 1}
            key={`${platform}${index}`}
          >
            {convertToParagraphs(platform.content).map(
              (paragraph: string, index: number) => (
                <CaptionParagraph key={index}>
                  {index === 0 ? (
                    <SocialLabel platform={platform.social_network.name} />
                  ) : null}

                  {paragraph}
                </CaptionParagraph>
              )
            )}
            {data.headline && <LeadAdCaption>{data.headline}</LeadAdCaption>}
          </Caption>
        ))}
        {data.boost_post[0]?.button && (
          <ButtonContainer>
            <ButtonOutline
              isDisabled={true}
              label={data.boost_post[0]?.label}
            />
            <Link url={`${data.boost_post[0].url}`} />
          </ButtonContainer>
        )}
      </ContentContainer>
    </Container>
  );
};

export default PostCard;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  overflow: hidden;
  background: #f0eae4;
  min-width: 100%;
  max-width: 100%;
`;

const Img = styled(motion.img)`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const Video = styled(motion.video)`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

type CaptionProps = {
  $isLast: boolean;
};

const Caption = styled.div<CaptionProps>`
  border-bottom: ${({ $isLast }) =>
    $isLast ? "0px solid #9d9d9d" : "1px solid #9d9d9d"};
`;

const CaptionParagraph = styled.div`
  display: inline-block;
  margin-bottom: 1.8rem;
`;

const ContentContainer = styled.div`
  margin: 2rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-bottom: 2rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
`;

const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`;

type ImgContainerProps = {
  $height: number;
};

const ImgContainer = styled.div<ImgContainerProps>`
  background-color: black;
  height: ${({ $height }) => `${$height}px`};
  min-height: ${({ $height }) => `${$height}px`};
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Standard syntax */
`;

const LeadAdCaption = styled.div`
  font-weight: 800;
`;
