import styled from "styled-components";

type Props = {
  url: string;
  color: string;
};

const EmailHeader: React.FC<Props> = ({ url, color }) => {
  return (
    <Container $bgColor={color}>
      <Img src={url} />
    </Container>
  );
};

export default EmailHeader;

type ContainerProps = {
  $bgColor: string;
};

const Container = styled.div<ContainerProps>`
  background-color: ${({ $bgColor }) => $bgColor};
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
`;

const Img = styled.img`
  width: 30%;
  padding: 2.5rem 0;
`;
