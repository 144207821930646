import { styled } from "styled-components";
import Spinner from "./Spinner";

type Props = {
  label?: string;
  isDisabled?: boolean;
  clickHandler?: () => void;
  isLoading?: boolean;
};

const ButtonOutline: React.FC<Props> = ({
  label,
  isDisabled,
  clickHandler,
  isLoading,
}) => {
  return (
    <OuterContainer onClick={clickHandler}>
      <Container $isDisabled={isDisabled}>
        {!isLoading && <Label $isDisabled={isDisabled}>{label ? label : "Learn more"}</Label>}
        {isLoading &&<Spinner color="black"/>}
      </Container>
    </OuterContainer>
  );
};

export default ButtonOutline;

const OuterContainer = styled.div`
  width: 100%;
  & :active {
    scale: 1.02;
  }
`;

type ContainerProps = {
  $isDisabled?: boolean;
};

const Container = styled.div<ContainerProps>`
  width: 100%;
  border-radius: 50px;
  border: ${({ $isDisabled }) =>
    $isDisabled
      ? "1px solid rgba(0, 0, 0, 0.25)"
      : "1px solid rgba(0, 0, 0, 1)"};
  height: 5.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ $isDisabled }) => !$isDisabled && "pointer"};
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Standard syntax */
  background-color: white;
`;

type LabelProps = {
  $isDisabled?: boolean;
};

const Label = styled.div<LabelProps>`
  color: ${({ $isDisabled }) => ($isDisabled ? "#808080" : "#101010")};
`;
