import { styled } from "styled-components";

type Props = {
  status?: boolean;
  title: string;
  subtitle?: string;
};

const Message: React.FC<Props> = ({ status, title, subtitle }) => {

  return (
    <Container>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2974_11946)">
          <circle
            cx="24"
            cy="24"
            r="23.5"
            stroke="black"
            strokeOpacity="0.75"
          />
          <g clipPath="url(#clip1_2974_11946)">
            <path
              d="M35.5822 29.6206L32.0035 26.1024V19.9833C32.0024 18.9327 31.7943 17.8927 31.3912 16.9225C30.988 15.9524 30.3978 15.0711 29.6541 14.3291C28.1521 12.8304 26.1164 11.9898 23.9946 11.9922C21.8729 11.9945 19.839 12.8397 18.3404 14.3416C16.8418 15.8436 16.0012 17.8794 16.0035 20.0011V26.09L12.4177 29.6224C12.2211 29.8157 12.0865 30.0631 12.031 30.3332C11.9755 30.6033 12.0017 30.8837 12.1062 31.1389C12.2106 31.394 12.3887 31.6123 12.6177 31.7658C12.8467 31.9194 13.1163 32.0013 13.392 32.0011H20C20 33.062 20.4214 34.0794 21.1715 34.8295C21.9217 35.5797 22.9391 36.0011 24 36.0011C25.0608 36.0011 26.0783 35.5797 26.8284 34.8295C27.5785 34.0794 28 33.062 28 32.0011H34.6044C34.8804 32.0015 35.1503 31.9197 35.3797 31.7662C35.609 31.6127 35.7875 31.3944 35.8924 31.1391C35.9972 30.8838 36.0237 30.603 35.9685 30.3326C35.9133 30.0622 35.7788 29.8144 35.5822 29.6206V29.6206ZM17.4222 20.0011C17.4198 18.2565 18.1106 16.5825 19.3425 15.3473C20.5744 14.112 22.2465 13.4168 23.9911 13.4144C25.7356 13.4121 27.4096 14.1028 28.6449 15.3347C29.8801 16.5666 30.5754 18.2388 30.5778 19.9833V26.1006L17.4222 26.0882V20.0011ZM24 34.5789C23.3166 34.5779 22.6615 34.306 22.1782 33.8228C21.695 33.3396 21.4231 32.6845 21.4222 32.0011H26.5778C26.5768 32.6839 26.3054 33.3384 25.823 33.8216C25.3405 34.3047 24.6863 34.577 24.0035 34.5789H24ZM27.7333 30.5789H13.4737L16 28.09L16.5866 27.5122H17.4222L30.5778 27.5264H31.4169L32 28.0989L34.5244 30.5878L27.7333 30.5789Z"
              fill="#1A1A1A"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_2974_11946">
            <rect width="48" height="48" fill="white" />
          </clipPath>
          <clipPath id="clip1_2974_11946">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(12 12)"
            />
          </clipPath>
        </defs>
      </svg>
      <div style={{ color: !status ? 'red' : '' }}>
        <div>{title}</div>
        {subtitle && (
          <div style={{ color: !status ? 'red' : '' }}>{subtitle}</div>
        )}
      </div>
    </Container>
  );
};

export default Message;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 36rem;
  gap: 1rem;
`;
