import { styled } from "styled-components";

type Props = {
  url: string;
};

const Link: React.FC<Props> = ({ url }) => {
  return (
    <Container>
      <svg
        width="15"
        height="16"
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2957_11706)">
          <path
            d="M7.33331 10.6348L6.30831 11.6515C5.99639 11.9594 5.57575 12.132 5.13748 12.132C4.6992 12.132 4.27856 11.9594 3.96664 11.6515C3.81458 11.4998 3.69394 11.3196 3.61162 11.1212C3.52931 10.9227 3.48694 10.71 3.48694 10.4952C3.48694 10.2804 3.52931 10.0677 3.61162 9.86934C3.69394 9.67093 3.81458 9.49071 3.96664 9.33899L5.92081 7.4015C6.23272 7.0936 6.65336 6.92097 7.09164 6.92097C7.52992 6.92097 7.95056 7.0936 8.26248 7.4015C8.46098 7.59871 8.6046 7.84429 8.67914 8.114C8.72014 8.08017 8.75782 8.0425 8.79164 8.0015L9.32914 7.46816C9.20879 7.21765 9.04677 6.98941 8.84998 6.79316C8.38192 6.33007 7.75007 6.07031 7.09164 6.07031C6.43321 6.07031 5.80136 6.33007 5.33331 6.79316L3.37498 8.74733C3.14431 8.97623 2.96123 9.24853 2.83629 9.54853C2.71136 9.84852 2.64703 10.1703 2.64703 10.4952C2.64703 10.8202 2.71136 11.142 2.83629 11.442C2.96123 11.742 3.14431 12.0143 3.37498 12.2432C3.84303 12.7063 4.47488 12.966 5.13331 12.966C5.79174 12.966 6.42359 12.7063 6.89164 12.2432L8.41248 10.7348H8.20414C7.91073 10.74 7.61792 10.7063 7.33331 10.6348Z"
            fill="#808080"
          />
          <path
            d="M11.9209 3.75801C11.4528 3.29491 10.821 3.03516 10.1625 3.03516C9.50409 3.03516 8.87224 3.29491 8.40419 3.75801L6.88336 5.26634H7.08752C7.38307 5.26626 7.67732 5.3055 7.96252 5.38301L8.98752 4.36634C9.29944 4.05845 9.72008 3.88581 10.1584 3.88581C10.5966 3.88581 11.0173 4.05845 11.3292 4.36634C11.4813 4.51806 11.6019 4.69828 11.6842 4.89668C11.7665 5.09509 11.8089 5.30779 11.8089 5.52259C11.8089 5.73739 11.7665 5.95009 11.6842 6.14849C11.6019 6.3469 11.4813 6.52712 11.3292 6.67884L9.37502 8.61634C9.06311 8.92423 8.64247 9.09687 8.20419 9.09687C7.76591 9.09687 7.34527 8.92423 7.03336 8.61634C6.83485 8.41913 6.69124 8.17354 6.61669 7.90384C6.57498 7.93219 6.53593 7.96426 6.50002 7.99967L5.96252 8.53301C6.08287 8.78351 6.2449 9.01176 6.44169 9.20801C6.90974 9.6711 7.54159 9.93085 8.20002 9.93085C8.85846 9.93085 9.4903 9.6711 9.95836 9.20801L11.9125 7.27051C12.1427 7.04132 12.3253 6.76893 12.4499 6.46897C12.5746 6.16902 12.6387 5.8474 12.6387 5.52259C12.6387 5.19778 12.5746 4.87616 12.4499 4.5762C12.3253 4.27625 12.1427 4.00386 11.9125 3.77467L11.9209 3.75801Z"
            fill="#808080"
          />
        </g>
        <defs>
          <clipPath id="clip0_2957_11706">
            <rect
              width="15"
              height="15"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
      {url}
    </Container>
  );
};

export default Link;

const Container = styled.div`
  display: flex;
  gap: 0.4rem;
  justify-content: center;
  align-items: center;
  color: #4b4b4c;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
`;
