import { styled } from "styled-components";

const LandingScreen = () => {
  const windowWidth = window.innerWidth;

  return (
    <Container>
      <LeftContainer>
        <Img src="/imgs/chickaros1.png" alt="Chickaros burger" />
        <Img src="/imgs/chickaros2.png" alt="Chickaros burger" />
      </LeftContainer>
      <RightContainer>
        <ImgLogo src="/imgs/chickaros-logo.png" alt="Chickaros burger" />
        <ImgMobile src="/imgs/chickaros-mobile.png" alt="Chickaros burger" />
        <Title>
          Join the club! Enjoy <br />
          <TextRed>50% OFF ALL FOOD</TextRed>
          <br />
          at Chickaro’s Birmingham Edgbaston from 5pm to 7 pm
        </Title>
        <Address>
          Our address:{" "}
          <LinkStyled href="https://" target="new">
            27 Chad Square Birmingham B15 3TQ
          </LinkStyled>
        </Address>
        <iframe
          src="https://cdn.forms-content.sg-form.com/03565837-4669-11ee-9c13-7a17b4d19573"
          // src="https://email.reallysocial.media/h/i/3085364268349204"
          // src={htmlcontent}
          title="Embedded Form"
          width={windowWidth < 800 ? "100%" : "80%"} // Set the width and height as needed
          height="400"
          frameBorder="0" // To remove the border around the iframe
        />
        <Details>
          Just follow these 3 easy steps:​ <br />
          <br />
          Step 1: Sign up to our newsletter
          <br />
          Step 2: Check your email for your code <br />
          Step 3: Use your exclusive voucher code when you book a table! <br />
          <br />
          <TextRed>
            Your voucher is valid from 5pm - 7pm every day (and from 3pm - 7pm
            on Friday).
          </TextRed>{" "}
          Advance booking only.
        </Details>
        <Terms>
          T&C apply. <LinkStyled href="">Privacy policy</LinkStyled>
        </Terms>
      </RightContainer>
    </Container>
  );
};

export default LandingScreen;

const Container = styled.div`
  display: flex;
  width: 100vw;
  max-width: 1440px;
  margin: 0 auto;
`;

const LeftContainer = styled.div`
  width: 50%;
  flex-grow: 1;
  gap: 0;
  display: flex;
  flex-direction: column;
  @media (max-width: 800px) {
    display: none;
  }
`;

const RightContainer = styled.div`
  width: 50%;
  flex-grow: 1;
  background-color: #111;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const Img = styled.img`
  width: 100%;
`;

const ImgLogo = styled.img`
  width: 32vw;
  margin-top: 1vw;
  @media (max-width: 800px) {
    width: 50vw;
    margin-top: 3vw;
    display: none;
  }
`;

const ImgMobile = styled.img`
  display: none;
  @media (max-width: 800px) {
    width: 100%;
    display: block;
  }
`;

const Title = styled.div`
  color: #fff;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 2vw;
  margin-top: 2vw;
  @media (max-width: 800px) {
    font-size: 7vw;
  }
`;
const TextRed = styled.span`
  color: #ed1f45;
`;

const Address = styled.div`
  color: #fff;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 1.4vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (max-width: 800px) {
    font-size: 4vw;
  }
`;

const LinkStyled = styled.a`
  color: white;
`;

const Details = styled.div`
  color: #fff;
  font-family: Roboto, sans-serif;
  font-size: 2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 10vw;
  @media (max-width: 800px) {
    font-size: 6vw;
    padding: 0 10vw 10vw 10vw;
  }
`;

const Terms = styled.div`
  color: white;
  margin-bottom: 10vw;
`;
