import styled from "styled-components";

import TextWithParagraphs from "../../../utils/TextWithParagraphs";
import ButtonOutline from "../../ui/ButtonOutline";
import Link from "../post/Link";

type Props = {
  img: string;
  message: string;
  buttonText: string;
  buttonUrl: string;
};

export default function WhatsappMessage({
  img,
  message,
  buttonText,
  buttonUrl,
}: Props) {
  return (
    <>
      <Label>WHATSAPP MESSAGE ↓</Label>
      <ContentContainer>
        <MainImg src={img} />
        <Spacer />
        <Body>{TextWithParagraphs(message)}</Body>
        <Spacer />
        <ButtonContainer>
          <ButtonOutline isDisabled={true} label={buttonText} />
          <Link url={buttonUrl} />
          <Spacer />
        </ButtonContainer>
      </ContentContainer>
    </>
  );
}

const Spacer = styled.div`
  min-height: 3rem;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  gap: 0;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 0.25);
  border-left: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 2.4rem;
  overflow: hidden;

  background: #f0eae4;
`;

const Label = styled.div`
  border-radius: 24px;
  background: #a17af2;
  color: white;
  width: 100%;
  padding: 3rem 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem;
`;

const MainImg = styled.img`
  width: 100%;
`;

const Body = styled.div`
  padding: 0 2rem;
  text-align: left;
  color: black;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
`;
