const DownArrow: React.FC = () => {
  return (
    <svg
      width="57"
      height="57"
      viewBox="0 0 57 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28.5" cy="28.5" r="28.5" fill="black" />
      <line
        x1="28.3574"
        y1="39.3513"
        x2="28.4946"
        y2="13.9938"
        stroke="#A17AF2"
        strokeWidth="3"
      />
      <path
        d="M28.4997 39.3341L19.5207 30.3551C18.8582 29.6926 17.7841 29.6926 17.1216 30.3551C16.4591 31.0176 16.4591 32.0917 17.1216 32.7542L27.3001 42.9328C27.9626 43.5953 29.0368 43.5953 29.6993 42.9328L39.8778 32.7542C40.5403 32.0917 40.5403 31.0176 39.8778 30.3551C39.2153 29.6926 38.1412 29.6926 37.4787 30.3551L28.4997 39.3341Z"
        fill="#A17AF2"
      />
    </svg>
  );
};

export default DownArrow;
