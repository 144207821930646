import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import toast from "react-hot-toast";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';

// components
import PostCard from "../post/PostCard";
import EmailHeader from "../email/EmailHeader";
import WhatsappMessage from "./WhatsappMessage";
import ApprovalCount from "../approval/ApprovalCount";

// hooks
import useApprove from "../../../hooks/useApprove";

// other
import api from "../../../services/api/api";
import ButtonOutline from "../../ui/ButtonOutline";
import ButtonBlack from "../../ui/ButtonBlack";
import DownArrow from "../post/DownArrow";
import TextWithParagraphs from "../../../utils/TextWithParagraphs";

const buttonLabels = [
  "",
  "Book Now",
  "Order Now",
  "Learn More",
  "Get Offer",
  "Sign Up",
];

const LeadAdScreen: React.FC = () => {
  const { isScrolledToBottom } = useApprove();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdatingSkip, setIsUpdatingSkip] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<any>();
  const params = useParams();
  const location = useLocation();
  const fullPath = window.location.origin + location.pathname;
  const fetchUrl = `/pa/lead-ads/info/${params.token}`;
  const hasQrCode = data?.lead_ad?.qrcode;
  const navigate = useNavigate();

  const isApproved = !!data?.lead_ad?.client_approved?.length;
  const isWhatsapp = !!data?.lead_ad?.whats_app_template;

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(fullPath)
      .then(() => {
        toast.success("Copied to the clipboard!");
      })
      .catch((error) => {
        toast.error("Something went wrong... Couldn't perform this operation");
      });
  };

  const approveHandler = async () => {
    setIsUpdating(true);
    let response;
    try {
      response = await api.get(`/pa/lead-ads/save/${params.token}` + location.search);
      if (response.data === "success") {
        toast.success("Campaign has been approved!");

        if(data.content_approval){
          setData(null);
          navigate(data.content_approval.next);
        } else {
          const resp = await api.get(`${fetchUrl}`);
          setData(resp.data);
        }
        
        setIsUpdating(false);
      } else {
        throw new Error();
      }
    } catch {
      toast.error("Something went wrong. Try again!");
      setIsUpdating(false);
    }
  };

  const skipHandler = async () => {
    setIsUpdatingSkip(true);
    try {

      const method = isApproved ? '/approve' : '/skip';

      await api.get(`/pa/content-approval/`  + data.content_approval.id + method);
        toast.success("Item has been skipped!");
        setIsUpdatingSkip(false);
        setData(null)
        window.scrollTo(0, 0);
        navigate(data.content_approval.next);
    } catch {
      toast.error("Something went wrong. Try again!");
      setIsUpdatingSkip(false);
    }
  };

  useEffect(() => {
    if (data) {
      return;
    }
    const fetchData = async () => {
      let resp;
      try {
        resp = await api.get(fetchUrl  + location.search);
        if (!resp.data.lead_ad.description) {
          throw new Error();
        }
        setData(resp.data);
      } catch (err) {
        setIsError(true);
      }
    };
    fetchData();
  }, [data, fetchUrl, params.token]);

  if (isError) {
    return (
      <Container>
        <LoaderContainer>
          It looks like you have a wrong link...
        </LoaderContainer>
      </Container>
    );
  }

  if (!data) {
    return (
      <Container>
        <LoaderContainer>Loading...</LoaderContainer>
      </Container>
    );
  }

  const postDataObj = {
    post_type: {
      name: "Lead Ad",
    },
    social_networks: [
      {
        content: data.lead_ad.message,
        social_network_id: 2,
        social_network: { name: "Facebook" },
      },
    ],
    files: [{ reference: data.lead_ad.file }],
    boost_post: [
      {
        button: 2,
        url: data.lead_ad.url,
        label: buttonLabels[data.lead_ad.call_to_action],
      },
    ],
    headline: data.lead_ad.description,
  };

  return (
    <>

      <Helmet>
        <title>{data.lead_ad.description}</title>
        <meta property="og:title" content={data.lead_ad.description} />
        <meta property="og:description" content={data.lead_ad.message} />
        <meta property="og:image" content={data.lead_ad.file} />
        <meta property="og:url" content={fullPath} />
        <meta name="twitter:card" content="summary_large_image" />
        {/* More meta tags as needed */}
      </Helmet>
      <Container>
        {data.content_approval && <ApprovalCount pagination={data.content_approval}></ApprovalCount>}
        <EmailLabel>FACEBOOK LEAD AD ↓</EmailLabel>
        <PostCard data={postDataObj} />
        <Spacer />
        <Spacer />

        {isWhatsapp && (
          <WhatsappMessage
            img={data?.lead_ad?.whats_app_template.image}
            message={data?.lead_ad?.whats_app_template.message}
            buttonText={data?.lead_ad?.whats_app_template.button_text}
            buttonUrl={data?.lead_ad?.whats_app_template.button_url}
          />
        )}
        {!isWhatsapp && (
          <>
            <EmailLabel>WELCOME EMAIL ↓</EmailLabel>
            <ContentContainer $bgColor={data.template?.body_background_color}>
              <EmailHeader
                color={data.template?.header_background_color}
                url={data.template.logo}
              />
              <MainImg src={data.lead_ad.email_1_image} />
              <Spacer />
              <ContentTitle
                $fontSize={`${data.template?.title_font_size}px`}
                $fontColor={data.template?.title_font_color}
                $font={data.template?.title_font_style}
              >
                {data.lead_ad.email_1_headline}
              </ContentTitle>
              <Spacer />
              <Body
                $fontSize={`${data.template?.body_font_size}px`}
                $fontColor={data.template?.body_font_color}
                $font={data.template?.body_font_style}
              >
                {TextWithParagraphs(data.lead_ad.email_1_message)}
              </Body>
              <ButtonLink href={data.lead_ad.email_1_button_url} target="new">
                <Button
                  $bgColor={data.template?.button_background_color}
                  $color={data.template?.button_font_color}
                  $fontSize={`${data.template?.button_font_size}px`}
                  $fontStyle={data.template?.button_font_style}
                >
                  {data.lead_ad.email_1_button_text}
                </Button>
              </ButtonLink>
              {hasQrCode && (
                <QrCode src="/imgs/qrcode.png" alt="qr code example" />
              )}
              <FooterContainer
                $bgColor={data.template.footer_background_color}
                $color={data.template.footer_font_color}
                $fontSize={data.template.body_font_size}
                $font={data.template.body_font_style}
              >
                <FooterParagraph>{data.lead_ad.client.name}</FooterParagraph>
                <FooterParagraph>{data.template.address}</FooterParagraph>
                <FooterParagraph>
                  Unsubscribe - Unsubscribe Preferences
                </FooterParagraph>
              </FooterContainer>
            </ContentContainer>
          </>
        )}

        <BottomContainer>
          {!isScrolledToBottom && <DownArrow />}
          {isScrolledToBottom && (
            <>
              <ButtonOutline label="Copy link" clickHandler={copyToClipboard} />
              {data.content_approval && <ButtonOutline label="Skip" clickHandler={skipHandler} isLoading={isUpdatingSkip}/>}
              {isApproved ? (
                <ButtonBlack
                  label="Approved!"
                  bgColor="#9B7CEB"
                  onClick={() => {}}
                />
              ) : (
                <ButtonBlack
                  label="Approve"
                  onClick={approveHandler}
                  isLoading={isUpdating}
                />
              )}
            </>
          )}
        </BottomContainer>
      </Container>
    </>
  );
};

const Container = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding: 1rem 1rem 24rem 1rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoaderContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type ContentContainerProps = {
  $bgColor: string;
};

const ContentContainer = styled.div<ContentContainerProps>`
  background-color: ${({ $bgColor }) => $bgColor};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  gap: 0;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 0.25);

  border-left: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
`;

const MainImg = styled.img`
  width: 100%;
`;

type ContentTitleType = {
  $fontSize: string;
  $fontColor: string;
  $font: string;
};

const ContentTitle = styled.div<ContentTitleType>`
  text-align: center;
  font-size: ${({ $fontSize }) => `${$fontSize}px`};
  color: ${({ $fontColor }) => $fontColor};
  font-family: ${({ $font }) => $font};
  margin: 0 0.5rem;
`;

type BodyType = {
  $fontSize: string;
  $fontColor: string;
  $font: string;
};

const Body = styled.div<BodyType>`
  padding: 0 4rem;
  text-align: center;
  color: ${({ $fontColor }) => $fontColor};
  font-family: ${({ $font }) => $font};
  font-size: ${({ $fontSize }) => `${$fontSize}px`};
`;

type ButtonProps = {
  $bgColor: string;
  $color: string;
  $fontSize: string;
  $fontStyle: string;
};

const Button = styled.div<ButtonProps>`
  background-color: ${({ $bgColor }) => $bgColor};
  color: ${({ $color }) => $color};
  font-family: ${({ $fontStyle }) => $fontStyle};
  font-size: ${({ $fontSize }) => `${$fontSize}px`};
  cursor: pointer;
  border-radius: 1.4rem;
  margin: 3rem 0 3rem 0;
  text-align: center;
  padding: 1rem 2.4rem 1.4rem 2.4rem;
  width: auto;
`;

const ButtonLink = styled.a`
  text-decoration: none;
`;

type FooterContainerType = {
  $bgColor: string;
  $color: string;
  $fontSize: string;
  $font: string;
};

const FooterContainer = styled.div<FooterContainerType>`
  width: 100%;
  background-color: ${({ $bgColor }) => $bgColor};
  color: ${({ $color }) => $color};
  text-align: center;
  padding: 2rem 4rem;
  font-size: ${({ $fontSize }) => $fontSize};
  font-family: Space Grotesk, sans-serif;
`;

const FooterParagraph = styled.p`
  margin-bottom: 1rem;
`;

const Spacer = styled.div`
  min-height: 3rem;
`;

const BottomContainer = styled.div`
  position: fixed;
  bottom: 1.2rem;
  gap: 1rem;
  width: 100%;
  padding: 0 1rem 2rem 1rem;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const EmailLabel = styled.div`
  border-radius: 24px;
  background: #a17af2;
  color: white;
  width: 100%;
  padding: 3rem 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem;
`;

const QrCode = styled.img`
  width: 50%;
  margin-bottom: 4.2rem;
`;

export default LeadAdScreen;
