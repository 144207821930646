import { styled } from "styled-components";

type Props = {
  pagination: any;
};

const Dots: React.FC<Props> = ({pagination}) => {

  return (
    <Container>
      {pagination.page} / {pagination.total}
    </Container>
  );
};

export default Dots;

const Container = styled.div`
  display: flex;
  gap: 1rem;

  cursor: pointer;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
`;
