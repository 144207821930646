import { useState, useEffect } from "react";

const useApprove = () => {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    const scrollTop = window.scrollY;

    if (scrollTop + windowHeight + 50 >= documentHeight) {
      setIsScrolledToBottom(true);
    } else if (windowHeight === documentHeight) {
      setIsScrolledToBottom(true);
    } else {
      setIsScrolledToBottom(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleScroll();
    }, 300);
  }, []);

  useEffect(() => {
    let timeout1: NodeJS.Timeout;
    let timeout2: NodeJS.Timeout;

    const onScroll = () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
      timeout1 = setTimeout(handleScroll, 30);
      timeout2 = setTimeout(handleScroll, 150);
    };

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
      clearTimeout(timeout1);
      clearTimeout(timeout2);
    };
  }, []);

  return { isScrolledToBottom };
};

export default useApprove;
