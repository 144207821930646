const TextWithParagraphs = (text: string) => {
  const paragraphs = text.split("\n").map((paragraph, index) => (
    <p key={index}>
      {paragraph}
      <br />
    </p>
  ));

  return <>{paragraphs}</>;
};

export default TextWithParagraphs;
