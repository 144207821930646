import { styled } from "styled-components";

const FeedbackScreen: React.FC = () => {
  return (
    <Container>
      <div>
        <Text>Thanks a lot for your feedback!</Text>
      </div>

      {/* 
      <LinkContainer>
        <Link href="https://search.google.com/local/writereview?placeid=ChIJtxklUK4jbkgRLJyDV5pwe7k">
          PLEASE RATE US
          <br /> ON GOOGLE
        </Link>
      </LinkContainer>
      <div />
      <Text>
        Your honest review <br />
        will help others discover great 🍕🍕
      </Text> */}
    </Container>
  );
};

export default FeedbackScreen;

const Container = styled.div`
  max-width: 80rem;
  color: white;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: Roboto, sans-serif;
  font-size: 2.2rem;
  line-height: 3rem;
  flex-direction: column;
  gap: 2rem;
`;

const Text = styled.p`
  text-align: center;
  max-width: 55rem;
`;

const LinkContainer = styled.div`
  text-align: center;
`;

const Link = styled.a`
  font-size: 4.2rem;
  color: #d85417;
  line-height: 4.8rem;
`;
