import { styled } from "styled-components";

import Spinner from "./Spinner";

type Props = {
  label?: string;
  onClick: () => void;
  bgColor?: string;
  disabled?: boolean;
  isLoading?: boolean;
};

const ButtonBlack: React.FC<Props> = ({
  label,
  onClick,
  bgColor,
  isLoading,
}) => {
  return (
    <OuterContainer>
      <Container onClick={onClick} $bgColor={bgColor}>
        {!isLoading && <Label>{label ? label : "Next"}</Label>}
        {isLoading && <Spinner />}
      </Container>
    </OuterContainer>
  );
};

export default ButtonBlack;

type ContainerProps = {
  $bgColor?: string;
};

const OuterContainer = styled.div`
  width: 100%;
  & :active {
    scale: 1.02;
  }
`;

const Container = styled.div<ContainerProps>`
  width: 100%;
  border-radius: 50px;
  border: ${({ $bgColor }) =>
    $bgColor ? `1px solid ${$bgColor}` : "1px solid rgba(0, 0, 0, 0.25)"};
  height: 5.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ $bgColor }) => ($bgColor ? $bgColor : "#101010")};
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Standard syntax */
`;

const Label = styled.div`
  color: #f2f2f2;
`;
