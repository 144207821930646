import { styled } from "styled-components";

type Props = {
  currentImg: number;
  totalImgs: number;
  onClick: () => void;
};

const Dots: React.FC<Props> = ({ currentImg, totalImgs, onClick }) => {
  const dotsArray = Array.from({ length: totalImgs }, (_, index) => index);

  return (
    <Container onClick={onClick}>
      {dotsArray.map((_, index) =>
        index === currentImg ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="10"
            viewBox="0 0 11 10"
            fill="none"
            key={`dot${index}`}
          >
            <circle cx="5.5" cy="5" r="5" fill="#454F66" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="10"
            viewBox="0 0 11 10"
            fill="none"
            key={`dot${index}`}
          >
            <circle cx="5.5" cy="5" r="5" fill="white" />
          </svg>
        )
      )}
    </Container>
  );
};

export default Dots;

const Container = styled.div`
  display: flex;
  gap: 1rem;

  cursor: pointer;
`;
