import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Toaster } from "react-hot-toast";

// components
import PostScreen from "./components/features/post/PostScreen";
import LandingScreen from "./components/features/landing/LangingScreen";
import Feedback5Screen from "./components/features/feedback/Feedback5Screen";
import FeedbackScreen from "./components/features/feedback/FeedbackScreen";
import EmailScreen from "./components/features/email/EmailScreen";
import LeadAdScreen from "./components/features/leadad/LeadAdScreen";
import WhatsappScreen from "./components/features/whatsapp/whatsapp-screen";
import ApprovalScreen from "./components/features/approval/ApprovalScreen";
import ApprovalFinishScreen from "./components/features/approval/ApprovalFinishScreen";

const router = createBrowserRouter([
  {
    path: "/:token",
    element: <PostScreen />,
  },
  {
    path: "/",
    element: <PostScreen />,
  },
  { path: "/email/:token", element: <EmailScreen /> },
  { path: "/lead-ads/:token", element: <LeadAdScreen /> },
  { path: "/whats-app/:token", element: <WhatsappScreen /> },

  {
    path: "/restaurant/:name",
    element: <LandingScreen />,
  },
  { path: "/feedback/thanks", element: <Feedback5Screen /> },
  { path: "/feedback/", element: <FeedbackScreen /> },

  { path: "/approval/:token", element: <ApprovalScreen /> },
  { path: "/approval/:token/finish", element: <ApprovalFinishScreen /> },

]);

// ,,,

function App() {
  return (
    <>
      <RouterProvider router={router} />
      <Toaster
        position="top-center"
        gutter={12}
        containerStyle={{ margin: "8px" }}
        toastOptions={{
          success: { duration: 5000 },
          error: { duration: 10000 },
          style: {
            fontSize: "16px",
            maxWidth: "500px",
            padding: "16px 24px",
            backgroundColor: "#A17AF2",
            color: "white",
          },
        }}
      />
    </>
  );
}

export default App;
