// ODnCscKxTWF4UFVibGljQWNjZXNzIUDCow==

import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { useParams, useLocation } from "react-router";
import { motion } from "framer-motion";
import ButtonOutline from "../../ui/ButtonOutline";
import useApprove from "../../../hooks/useApprove";
import DownArrow from "../post/DownArrow";
import ButtonBlack from "../../ui/ButtonBlack";
import toast from "react-hot-toast";
import ApprovalCount from "./ApprovalCount";


import api from "../../../services/api/api";

const convertToParagraphs = (text: string) => {
  return text.split(/\r\n/);
};

export default function WhatsappScreen() {
  const [data, setData] = useState<any>();
  const [isError, setIsError] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [containerWidth, setContainerWidth] = useState(0);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const params = useParams();
  const location = useLocation();
  const fullPath = window.location.origin + location.pathname;

  const fetchUrl = `/pa/whats-app/info/${params.token}`;

  const windowHeight = window.innerHeight;

  useEffect(() => {
    if (containerRef.current) {
      const width = containerRef.current.clientWidth;
      setContainerWidth(width);
    }
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      let resp;
      try {
        resp = await api.get(fetchUrl);
        if (!resp.data?.content?.hasOwnProperty("id")) {
          throw new Error();
        }
        setData(resp.data.content);
      } catch (err) {
        setIsError(true);
      }
    };
    fetchData();
  }, [fetchUrl, params.token]);


  const approveHandler = async () => {
    setIsUpdating(true);
    let response;
    try {
      response = await api.get(`/pa/whats-app/save/${params.token}`);
      if (response.data === "success") {
        toast.success("Campaign has been approved!");
        const resp = await api.get(`${fetchUrl}`);
        setData(resp.data.content);
        setIsUpdating(false);
      } else {
        throw new Error();
      }
    } catch {
      toast.error("Something went wrong. Try again!");
      setIsUpdating(false);
    }
  };

  // if (isError) {
  //   return (
  //     <Container $windowHeight={windowHeight}>
  //       <LoaderContainer>
  //         It looks like you have a wrong link...
  //       </LoaderContainer>
  //     </Container>
  //   );
  // }

  // if (!data) {
    return (
      <Container $windowHeight={windowHeight}>
        <LoaderContainer>
        <LoaderContainerContent>
          <Img src="/logo192.png" alt="Really Social"  style={{marginBottom: '50px'}}/>
            <div>
              Nothing left to approve.<br/>
              Relax and enjoy!
            </div>
            </LoaderContainerContent>
        </LoaderContainer>

        
      </Container>
    );
  // }

}

const LoaderContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
`;

const LoaderContainerContent = styled.div`
  display: block;
`;

type ContainerProps = {
  $windowHeight: number;
};

const Container = styled.div<ContainerProps>`
  max-width: 500px;
  margin: 0 auto;
  padding: 1rem 1rem 14rem 1rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: ${({ $windowHeight }) => `${$windowHeight}px`};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  overflow: hidden;
  background: #f0eae4;
  min-width: 100%;
  max-width: 100%;
`;

type ImgContainerProps = {
  $height: number;
};

const ImgContainer = styled.div<ImgContainerProps>`
  background-color: black;
  height: ${({ $height }) => `${$height}px`};
  min-height: ${({ $height }) => `${$height}px`};
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Standard syntax */
`;

const Img = styled(motion.img)`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

type CaptionProps = {
  $isLast: boolean;
};

const Caption = styled.div<CaptionProps>`
  border-bottom: ${({ $isLast }) =>
    $isLast ? "0px solid #9d9d9d" : "1px solid #9d9d9d"};
`;

const CaptionParagraph = styled.div`
  display: inline-block;
  margin-bottom: 1.8rem;
`;

const InnerContentContainer = styled.div`
  margin: 2rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-bottom: 2rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
`;

const BottomContainer = styled.div`
  position: fixed;
  bottom: 1.2rem;
  gap: 1rem;
  width: 100%;
  padding: 0 1rem 2rem 1rem;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MessageContainer = styled.div`
  height: 24.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
